<template>
  <FormInputText :id="inputId" type="number" step=".01" :notRequired="notRequired" :disabled="disabled" :placeholder="placeholder" v-model="internalValue" />
</template>

<script>

import formInputFieldMixin from './mixins/formInputField.js';
import formInputTextPropsMixin from './mixins/formInputTextProps.js';

import FormInputText from './FormInputText.vue';

export default {
  mixins: [formInputFieldMixin, formInputTextPropsMixin],
  components: {
    FormInputText,
  },
  computed: {
    internalValue: {
      set (val) {
        this.$emit('input', parseFloat(val) * 100);
      },
      get () {
        return parseFloat(this.value) / 100;
      },
    },
  },
};

</script>
