import formInputTextPropsMixin from './formInputTextProps.js';

export default {
  mixins: [formInputTextPropsMixin],
  props: {
    step: {
      type: String,
      default: '.01',
    },
  },
};
