<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-2"
        label="Codice"
        v-model="internalValue.code"
        :errorMsg="errorHash.code"
        @input="removeFieldError('code')"
        :disabled="edit"
      />

      <SelectCurrencyWidget
        class="col-md-4"
        label="Valuta"
        v-model="internalValue.currencyCode"
        :errorMsg="errorHash.currencyCode"
        @input="removeFieldError('currencyCode')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Nome"
        v-model="internalValue.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />
    </div>
    <div class="row">
      <div class="col-md-6">
        <FormLabel label-is-fake not-required>Accise sul vino <button class="btn btn-sm btn-secondary" @click.prevent="addExciseDuty">+</button></FormLabel>
        <p v-if="!internalValue.exciseDuties || internalValue.exciseDuties.length === 0">Nessuna accisa definita</p>
        <template v-else>
          <div class="row" v-for="(exciseDuty, pos) in internalValue.exciseDuties" :key="'exciseDuty-' + pos">
            <div class="col-auto align-self-end pb-1">
              <button class="btn btn-sm btn-secondary" @click.prevent="removeExciseDuty(pos)">-</button>
            </div>
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° min"
              not-required
              v-model="exciseDuty.min"
            />
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° max"
              not-required
              v-model="exciseDuty.max"
            />
            <FormInputCurrencyWidget
              class="col"
              label="Accisa per hL"
              v-model="exciseDuty.exciseDuty"
              :currency="internalValue.currencyCode"
            />
          </div>
        </template>
      </div>
      <div class="col-md-6">
        <FormLabel label-is-fake not-required>Accise sugli spumanti <button class="btn btn-sm btn-secondary" @click.prevent="addSparklingExciseDuty">+</button></FormLabel>
        <p v-if="!internalValue.sparklingExciseDuties || internalValue.sparklingExciseDuties.length === 0">Nessuna accisa definita</p>
        <template v-else>
          <div class="row" v-for="(exciseDuty, pos) in internalValue.sparklingExciseDuties" :key="'sparklingExciseDuty-' + pos">
            <div class="col-auto align-self-end pb-1">
              <button class="btn btn-sm btn-secondary" @click.prevent="removeSparklingExciseDuty(pos)">-</button>
            </div>
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° min"
              not-required
              v-model="exciseDuty.min"
            />
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° max"
              not-required
              v-model="exciseDuty.max"
            />
            <FormInputCurrencyWidget
              class="col"
              label="Accisa per hL"
              v-model="exciseDuty.exciseDuty"
              :currency="internalValue.currencyCode"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <FormLabel label-is-fake not-required>Aliquote IVA <button class="btn btn-sm btn-secondary" @click.prevent="addVatRate">+</button></FormLabel>
        <p v-if="!internalValue.vatRates || internalValue.vatRates.length === 0">Nessuna aliquota IVA definita</p>
        <template v-else>
          <div class="row" v-for="(vatRate, pos) in internalValue.vatRates" :key="'vatRate-' + pos">
            <div class="col-auto align-self-end pb-1">
              <button class="btn btn-sm btn-secondary" @click.prevent="removeVatRate(pos)">-</button>
            </div>
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° min"
              not-required
              v-model="vatRate.min"
            />
            <FormInputTextWidget
              class="col"
              type="number"
              :min="0"
              step=".1"
              label="° max"
              not-required
              v-model="vatRate.max"
            />
            <FormInputPercentageWidget
              class="col"
              label="IVA"
              v-model="vatRate.vatRate"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 text-right">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputPercentageWidget from '@/libs/Form/components/Inputs/Widgets/FormInputPercentageWidget.vue';
import FormInputCurrencyWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCurrencyWidget.vue';
import FormLabel from '@/libs/Form/components/FormLabel.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import SelectCurrencyWidget from '@/views/components/Form/Widgets/SelectCurrencyWidget.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    SelectCurrencyWidget,
    FormInputTextWidget,
    FormInputCurrencyWidget,
    FormInputPercentageWidget,
    FeedbackAlert,
    FormLabel,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
        code: null,
        enabled: true,
        currencyCode: null,
        vatRates: [
          {
            min: null,
            max: null,
            vatRate: 0,
          },
        ],
        exciseDuties: [
          {
            min: null,
            max: null,
            exciseDuty: 0,
          },
        ],
        sparklingExciseDuties: [
          {
            min: null,
            max: null,
            exciseDuty: 0,
          },
        ],
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      iValue: { ...this.value },
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.edit && !this.iValue.code) {
        this.addError('Inserisci il codice', 'code');
      }

      if (!this.iValue.name) {
        this.addError('Inserisci il nome', 'name');
      }

      return !this.hasErrors;
    },
    removeVatRate (pos) {
      (this.internalValue.vatRates || []).splice(pos, 1);
    },
    addVatRate () {
      if (!this.internalValue.vatRates) {
        this.$set(this.internalValue, 'vatRates', []);
      }

      this.internalValue.vatRates.push({
        min: null,
        max: null,
        vatRate: 0,
      });
    },
    removeExciseDuty (pos) {
      (this.internalValue.exciseDuties || []).splice(pos, 1);
    },
    addExciseDuty () {
      if (!this.internalValue.exciseDuties) {
        this.$set(this.internalValue, 'exciseDuties', []);
      }

      this.internalValue.exciseDuties.push({
        min: null,
        max: null,
        exciseDuty: 0,
      });
    },
    removeSparklingExciseDuty (pos) {
      (this.internalValue.sparklingExciseDuties || []).splice(pos, 1);
    },
    addSparklingExciseDuty () {
      if (!this.internalValue.sparklingExciseDuties) {
        this.$set(this.internalValue, 'sparklingExciseDuties', []);
      }

      this.internalValue.sparklingExciseDuties.push({
        min: null,
        max: null,
        exciseDuty: 0,
      });
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const valueToReturn = { ...this.iValue };

      this.$emit('submitForm', valueToReturn);
    },
  },
};

</script>
