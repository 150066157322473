<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="currencyOptions" :value="value" optionValue="code" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue'),
  },
  props: {
    currencies: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      allCurrencies: null,
    };
  },
  computed: {
    completeCurrencies () {
      return this.currencies || this.allCurrencies || [];
    },
    currencyOptions () {
      return this.completeCurrencies.map(p => {
        p.value = p.id;
        p.label = p.name;

        return p;
      });
    },
  },
  mounted () {
    if (null === this.currencies) {
      this.$api.listCurrencies()
        .then(res => {
          this.allCurrencies = res.data.currencies;
        })
        .catch(this.$log.error)
      ;
    }
  },
};

</script>
